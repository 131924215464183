import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMemo } from "react";

import { paymentFormOnComplete } from "./onComplete";
import StripePaymentForm from "./StripePaymentForm";

interface StripePaymentProps {
  publishableKey: string;
  returnUrl: string;
  createSetupIntent: () => Promise<string>;
  onReady?: () => void;
  onComplete: paymentFormOnComplete;
  onCancel: () => void;
}

const StripePayment: React.FunctionComponent<StripePaymentProps> = ({
  publishableKey,
  returnUrl,
  createSetupIntent,
  onReady,
  onComplete,
  onCancel,
}) => {
  const stripePromise = useMemo(
    () => loadStripe(publishableKey),
    [publishableKey]
  );

  return (
    <Elements
      stripe={stripePromise}
      options={{ mode: "setup", currency: "usd" }}
    >
      <StripePaymentForm
        createSetupIntent={createSetupIntent}
        returnUrl={returnUrl}
        onReady={onReady}
        onComplete={onComplete}
        onCancel={onCancel}
      />
    </Elements>
  );
};

export default StripePayment;
