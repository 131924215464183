import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import deepEqual from "fast-deep-equal";
import { useState } from "react";
import { isPresent } from "ts-is-present";
import { theme } from "twin.macro";

import {
  platform_enum,
  RuleInlineSegmentFragment,
  RulePropertyFragment,
  RuleSegmentFragment,
  RuleSegmentGroupFragment,
  SegmentConditionFragment,
} from "../../__generated__/graphql";
import InlineSegmentInput from "../../features/segments/InlineSegmentInput";
import SegmentConditionDescription from "../../features/segments/SegmentConditionDescription";
import SegmentIntegrationIcon from "../../features/segments/SegmentIntegrationIcon";
import Button from "../form/Button";
import { psSegmentGroup } from "../icons/customIcons";
import Expander from "./Expander";
import { AnyBox, BoxContainer } from "./RuleBoxes";
import RuleExpander from "./RuleExpander";

export interface RuleSegmentPickerValue {
  segmentGroupIds: number[];
  segmentIds: number[];
  newConditions: SegmentConditionFragment[];
}

interface RuleSegmentPickerProps {
  segmentGroupsEnabled: boolean;
  platform: platform_enum | undefined;
  segmentGroups: RuleSegmentGroupFragment[];
  segments: RuleSegmentFragment[];
  inlineSegments: RuleInlineSegmentFragment[];
  properties: RulePropertyFragment[];
  value: RuleSegmentPickerValue;
  onChange: (value: RuleSegmentPickerValue) => void;
  onClickCreateSegmentGroup: () => void;
  onClickCreateSegment: () => void;
}

const RuleSegmentPicker: React.FunctionComponent<RuleSegmentPickerProps> = ({
  segmentGroupsEnabled,
  platform,
  segmentGroups,
  segments,
  inlineSegments,
  properties,
  value,
  onChange,
  onClickCreateSegmentGroup,
  onClickCreateSegment,
}) => {
  const [addingCondition, setAddingCondition] = useState(false);

  const conditions = [
    ...inlineSegments
      .filter((s) => value.segmentIds.includes(s.id))
      .map((s) => ({
        segmentId: s.id,
        condition:
          s.primary_segment_condition_group?.segment_condition_group_entries[0]
            .entry_segment_condition,
      }))
      .filter(isPresent),
    ...value.newConditions.map((c) => ({
      segmentId: -1,
      condition: c,
    })),
  ];

  const handleDeselectAll = () => {
    onChange({
      segmentGroupIds: [],
      segmentIds: [],
      newConditions: [],
    });
  };

  const handleAddCondition = (condition: SegmentConditionFragment) => {
    onChange({
      ...value,
      newConditions: [...value.newConditions, condition],
    });
    setAddingCondition(false);
  };

  const handleRemoveCondition = (index: number) => {
    const { segmentId, condition } = conditions[index];

    if (segmentId > 0) {
      onChange({
        ...value,
        segmentIds: value.segmentIds.filter((id) => id !== segmentId),
      });
    } else {
      const newConditionsIndex = value.newConditions.findIndex((c) =>
        deepEqual(c, condition)
      );
      if (newConditionsIndex > -1) {
        onChange({
          ...value,
          newConditions: [
            ...value.newConditions.slice(0, newConditionsIndex),
            ...value.newConditions.slice(newConditionsIndex + 1),
          ],
        });
      }
    }
  };

  const pickerValue = [
    ...value.segmentIds,
    ...value.segmentGroupIds.map((id) => id * -1),
  ];

  return (
    <div>
      <BoxContainer tw="mb-2">
        <AnyBox
          isOn={
            value.segmentGroupIds.length === 0 &&
            value.segmentIds.length === 0 &&
            conditions.length === 0
          }
          onClick={handleDeselectAll}
          disabled={!!conditions.length}
        >
          Any segment or condition
        </AnyBox>
      </BoxContainer>
      <RuleExpander
        title="Segments"
        items={[
          ...segments.map((s) => ({
            id: s.id,
            label: (
              <>
                {s.name}
                {!!s.integration && (
                  <SegmentIntegrationIcon
                    integrationType={s.integration.type}
                    color="light"
                  />
                )}
              </>
            ),
            search: s.name,
            group: s.integration?.type === "klaviyo" ? "Klaviyo" : undefined,
          })),
          ...(segmentGroupsEnabled
            ? segmentGroups.map((g) => ({
                id: g.id * -1, // This is such a gross hack and we hate it.
                label: (
                  <>
                    <FontAwesomeIcon
                      icon={psSegmentGroup}
                      color="white"
                      transform="shrink-4 left-3 down-1"
                    />
                    {g.name}
                  </>
                ),
                search: g.name,
                group: "Groups",
                color: theme`colors.purple.600`,
              }))
            : []),
        ]}
        itemColor={theme`colors.purple.600`}
        search={segments.length >= 20}
        selectAll={segments.length >= 20}
        itemName="segments"
        value={pickerValue}
        createButtons={[
          {
            label: "Create segment",
            disabled: !platform,
            disabledMessage:
              "Connect a subscription platform to create segments.",
            onClick: onClickCreateSegment,
          },
          segmentGroupsEnabled
            ? {
                label: "Create segment group",
                disabled: !platform || segments.length < 2,
                disabledMessage: !platform
                  ? "Connect a subscription platform to create segment groups."
                  : segments.length < 2
                  ? "You need at least two segments to create a segment group."
                  : "",
                onClick: onClickCreateSegmentGroup,
              }
            : undefined,
        ]}
        onChange={(ids) =>
          onChange({
            ...value,
            segmentIds: ids.filter((id) => id > 0),
            segmentGroupIds: ids.filter((id) => id < 0).map(Math.abs),
          })
        }
        tw="mb-4"
      />
      <Expander title="Conditions" defaultExpanded expandDisabled>
        <div tw="pt-3">
          {!!conditions.length && (
            <div tw="mb-3">
              {conditions.map(({ condition }, i) =>
                !!condition ? (
                  <div key={i} tw="mt-2 first:mt-0 last:mb-1">
                    <SegmentConditionDescription
                      condition={condition}
                      active
                      onRemove={() => handleRemoveCondition(i)}
                    />
                  </div>
                ) : null
              )}
            </div>
          )}

          {addingCondition && platform && (
            <InlineSegmentInput
              platform={platform}
              properties={properties}
              initialValue={undefined}
              onSave={handleAddCondition}
              onCancel={() => setAddingCondition(false)}
              tw="mb-3"
            />
          )}

          <div>
            <Tippy
              content="Connect a subscription platform before adding conditions."
              disabled={!!platform || addingCondition}
            >
              <span>
                <Button
                  type="button"
                  buttonType="alternate-secondary"
                  size="sm"
                  onClick={() => setAddingCondition(true)}
                  disabled={!platform || addingCondition}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add condition
                </Button>
              </span>
            </Tippy>
          </div>
        </div>
      </Expander>
    </div>
  );
};

export default RuleSegmentPicker;
