import tw from "twin.macro";

import {
  platform_enum,
  RuleDeflectionFragment,
  RuleFlowFragment,
  RuleFlowTestFragment,
  RuleInlineSegmentFragment,
  RuleOfferFragment,
  RuleOfferGroupFragment,
  RuleOfferTestFragment,
  RulePropertyFragment,
  RuleQuestionFragment,
  RuleSegmentFragment,
  RuleSegmentGroupFragment,
  SegmentConditionFragment,
} from "../../__generated__/graphql";
import { FlowText } from "../../features/public/flow/lib/types";
import RuleDeflectionPicker, {
  RuleDeflectionPickerValue,
} from "./RuleDeflectionPicker";
import RuleFlowPicker, { RuleFlowPickerValue } from "./RuleFlowPicker";
import RuleOfferPicker, { RuleOfferPickerValue } from "./RuleOfferPicker";
import RuleQuestionAnswerPicker, {
  RuleQuestionAnswerPickerValue,
} from "./RuleQuestionAnswerPicker";
import RuleSegmentPicker, { RuleSegmentPickerValue } from "./RuleSegmentPicker";

interface RuleEditorOfferGroup {
  weight: number;
  offerIds: number[];
  offerGroupIds: number[];
  offerTestIds: number[];
  deflectionIds: number[];
  offerAutopilotOfferIds: number[];
  includePresentNoOffer: boolean;
}

export interface RuleEditorFlowOfferRuleValue {
  type: "flow_offer_rule";
  segmentGroupIds: number[];
  segmentIds: number[];
  newConditions: SegmentConditionFragment[];
  questionOptionIds: number[];
  includeOtherInQuestionIds: number[];
  groups: RuleEditorOfferGroup[];
}

export interface RuleEditorFlowDeflectionRuleValue {
  type: "flow_deflection_rule";
  segmentGroupIds: number[];
  segmentIds: number[];
  newConditions: SegmentConditionFragment[];
  questionOptionIds: number[];
  includeOtherInQuestionIds: number[];
  groups: RuleEditorOfferGroup[];
}

export interface RuleEditorFlowRouteRuleValue {
  type: "flow_route_rule";
  segmentGroupIds: number[];
  segmentIds: number[];
  newConditions: SegmentConditionFragment[];
  flowIds: number[];
  flowTestIds: number[];
  eligibilityMessageIds: number[];
}

export interface RuleEditorCampaignOfferRuleValue {
  type: "campaign_offer_rule";
  segmentGroupIds: number[];
  segmentIds: number[];
  newConditions: SegmentConditionFragment[];
  groups: RuleEditorOfferGroup[];
}

export type RuleEditorValue =
  | RuleEditorFlowOfferRuleValue
  | RuleEditorFlowRouteRuleValue
  | RuleEditorCampaignOfferRuleValue
  | RuleEditorFlowDeflectionRuleValue;

interface RuleEditorProps {
  type?: "flow" | "eligibility_message";
  segmentGroupsEnabled: boolean;
  offerRuleGroupsEnabled: boolean;
  flowId?: number;
  platform: platform_enum | undefined;
  segmentGroups: RuleSegmentGroupFragment[];
  segments: RuleSegmentFragment[];
  inlineSegments: RuleInlineSegmentFragment[];
  properties: RulePropertyFragment[];
  questions?: RuleQuestionFragment[];
  offers?: RuleOfferFragment[];
  offerGroups?: RuleOfferGroupFragment[];
  offerTests?: RuleOfferTestFragment[];
  offerAutopilotOffers?: RuleOfferFragment[];
  deflections?: RuleDeflectionFragment[];
  flows?: RuleFlowFragment[];
  flowTests?: RuleFlowTestFragment[];
  eligibilityMessages?: any[];
  hideHeading?: boolean;
  flowText?: FlowText;
  value: RuleEditorValue;
  undeletableMessages?: number[];
  onChange: (value: RuleEditorValue) => void;
  onClickCreateSegmentGroup: () => void;
  onClickCreateSegment: () => void;
  onClickCreateOffer?: (groupIndex: number) => void;
  onClickCreateOfferTest?: (groupIndex: number) => void;
  onClickCreateDeflection?: (groupIndex: number) => void;
  onClickEditDeflection?: (deflectionId: number) => void;
  onClickCreateMessage?: (id?: number) => void;
  onClickDeleteMessage?: (id: number) => void;
}

const Column = tw.div`flex flex-col flex-1`;
const ColumnHeading = tw.div`bg-gray-100 border-b border-r last:border-r-0 border-divider py-2 w-full flex px-4 items-center font-semibold text-gray-600`;
const ColumnBody = tw.div`h-full pt-2 pb-4 px-4 border-r border-divider overflow-y-auto`;
const ColumnDescription = tw.div`text-sm text-gray-400 mb-4`;

const RuleEditor: React.FunctionComponent<RuleEditorProps> = ({
  type = "flow",
  segmentGroupsEnabled,
  offerRuleGroupsEnabled,
  flowId,
  platform,
  segmentGroups,
  segments,
  inlineSegments,
  properties,
  questions = [],
  offers = [],
  offerGroups = [],
  offerTests = [],
  offerAutopilotOffers = [],
  deflections = [],
  flows = [],
  flowTests = [],
  eligibilityMessages = [],
  value,
  hideHeading = false,
  flowText,
  undeletableMessages = [],
  onChange,
  onClickCreateSegmentGroup,
  onClickCreateSegment,
  onClickCreateOffer = () => undefined,
  onClickCreateOfferTest,
  onClickCreateDeflection = () => undefined,
  onClickEditDeflection = () => undefined,
  onClickCreateMessage = () => undefined,
  onClickDeleteMessage = (id) => undefined,
}) => {
  const handleChangeSegmentValue = (newValue: RuleSegmentPickerValue) => {
    onChange({
      ...value,
      segmentGroupIds: newValue.segmentGroupIds,
      segmentIds: newValue.segmentIds,
      newConditions: newValue.newConditions,
    });
  };

  const handleChangeQuestionAnswerValue = (
    newValue: RuleQuestionAnswerPickerValue
  ) => {
    if (
      value.type !== "flow_offer_rule" &&
      value.type !== "flow_deflection_rule"
    ) {
      throw new Error();
    }

    onChange({
      ...value,
      questionOptionIds: newValue.questionOptionIds,
      includeOtherInQuestionIds: newValue.includeOtherInQuestionIds,
    });
  };

  const handleChangeOfferValue = (newValue: RuleOfferPickerValue) => {
    if (
      value.type !== "flow_offer_rule" &&
      value.type !== "campaign_offer_rule"
    ) {
      throw new Error();
    }

    onChange({
      ...value,
      groups: newValue.groups.map((g) => ({
        deflectionIds: [],
        ...g,
      })),
    });
  };

  const handleChangeDeflectionValue = (newValue: RuleDeflectionPickerValue) => {
    if (value.type !== "flow_deflection_rule") {
      throw new Error();
    }

    onChange({
      ...value,
      groups: newValue.groups.map((g) => ({
        offerIds: [],
        offerGroupIds: [],
        offerTestIds: [],
        offerAutopilotOfferIds: [],
        ...g,
      })),
    });
  };

  const handleChangeFlowValue = (newValue: RuleFlowPickerValue) => {
    if (value.type !== "flow_route_rule") {
      throw new Error();
    }

    onChange({
      ...value,
      flowIds: newValue.flowIds,
      flowTestIds: newValue.flowTestIds,
      eligibilityMessageIds: newValue.eligibilityMessageIds,
    });
  };

  return (
    <div tw="w-full h-full flex flex-col">
      <div tw="w-full h-full flex overflow-hidden">
        <Column>
          {!hideHeading && <ColumnHeading>Condition</ColumnHeading>}
          <ColumnBody>
            <ColumnDescription>
              If the customer is in <em>any</em> of the selected segments or
              conditions…
            </ColumnDescription>
            <RuleSegmentPicker
              segmentGroupsEnabled={segmentGroupsEnabled}
              platform={platform}
              segmentGroups={segmentGroups}
              segments={segments}
              inlineSegments={inlineSegments}
              properties={properties}
              value={{
                segmentGroupIds: value.segmentGroupIds,
                segmentIds: value.segmentIds,
                newConditions: value.newConditions,
              }}
              onChange={handleChangeSegmentValue}
              onClickCreateSegmentGroup={onClickCreateSegmentGroup}
              onClickCreateSegment={onClickCreateSegment}
            />
          </ColumnBody>
        </Column>
        {(value.type === "flow_offer_rule" ||
          value.type === "flow_deflection_rule") && (
          <Column>
            {!hideHeading && <ColumnHeading>Answer</ColumnHeading>}

            <ColumnBody>
              <ColumnDescription>
                and chooses <em>any</em> of the selected answers for{" "}
                <em>each</em> question…
              </ColumnDescription>
              <RuleQuestionAnswerPicker
                questions={questions}
                value={{
                  questionOptionIds: value.questionOptionIds,
                  includeOtherInQuestionIds: value.includeOtherInQuestionIds,
                }}
                onChange={handleChangeQuestionAnswerValue}
                flowText={flowText}
              />
            </ColumnBody>
          </Column>
        )}
        {(value.type === "flow_offer_rule" ||
          value.type === "campaign_offer_rule") && (
          <Column>
            {!hideHeading && <ColumnHeading>Offer</ColumnHeading>}
            <ColumnBody>
              <ColumnDescription>
                then randomly present one of the selected options.
              </ColumnDescription>
              <RuleOfferPicker
                ruleGroupsEnabled={offerRuleGroupsEnabled}
                platform={platform}
                offers={offers}
                offerGroups={offerGroups}
                offerTests={offerTests}
                offerAutopilotOffers={offerAutopilotOffers}
                value={{
                  groups: value.groups,
                }}
                onChange={handleChangeOfferValue}
                onClickCreateOffer={onClickCreateOffer}
                onClickCreateOfferTest={onClickCreateOfferTest}
              />
            </ColumnBody>
          </Column>
        )}
        {value.type === "flow_deflection_rule" && flowId && (
          <Column>
            {!hideHeading && <ColumnHeading>Deflection</ColumnHeading>}
            <ColumnBody>
              <ColumnDescription>
                then randomly present one of the selected options.
              </ColumnDescription>
              <RuleDeflectionPicker
                ruleGroupsEnabled={offerRuleGroupsEnabled}
                deflections={deflections}
                flowId={flowId}
                value={{
                  groups: value.groups,
                }}
                onChange={handleChangeDeflectionValue}
                onClickCreateDeflection={onClickCreateDeflection}
                onClickEditDeflection={onClickEditDeflection}
              />
            </ColumnBody>
          </Column>
        )}
        {value.type === "flow_route_rule" && (
          <Column>
            {!hideHeading && <ColumnHeading>Flow</ColumnHeading>}
            <ColumnBody>
              <ColumnDescription>
                {type === "flow" ? (
                  <>then randomly route to one of the selected flows.</>
                ) : (
                  <>then randomly route to an eligibility message.</>
                )}
              </ColumnDescription>
              <RuleFlowPicker
                type={type}
                flows={flows}
                flowTests={flowTests}
                eligibilityMessages={eligibilityMessages}
                undeletableMessages={undeletableMessages}
                value={{
                  flowIds: value.flowIds,
                  flowTestIds: value.flowTestIds,
                  eligibilityMessageIds: value.eligibilityMessageIds,
                }}
                onChange={handleChangeFlowValue}
                onClickCreateMessage={onClickCreateMessage}
                onClickDeleteMessage={onClickDeleteMessage}
              />
            </ColumnBody>
          </Column>
        )}
      </div>
    </div>
  );
};

export default RuleEditor;
