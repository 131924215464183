import { gql, useQuery } from "@apollo/client";
import flow from "@prosperstack/flow";
import { FlowPayload, FlowResult } from "@prosperstack/flow";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";

import {
  NewFlowSessionQuery,
  platform_enum,
} from "../../../__generated__/graphql";
import env from "../../../common/env";
import useQueryParams from "../../../common/useQueryParams";

const NewFlowSession: React.FunctionComponent = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [flowCalled, setFlowCalled] = useState(false);
  const [result, setResult] = useState<FlowResult>();
  const [closed, setClosed] = useState(false);
  const [completedInternalId, setCompletedInteralId] = useState<string>();
  const [completedSubscriptionId, setCompletedSubscriptionId] =
    useState<string>();

  const queryParams = useQueryParams();

  (window as any).__PROSPERSTACK_DEBUG_APP_HOST__ = env("REACT_APP_APP_URL");
  (window as any).__PROSPERSTACK_DEBUG_API_HOST__ = env("REACT_APP_SERVER_URL");

  const { data } = useQuery<NewFlowSessionQuery>(
    gql`
      query NewFlowSessionQuery {
        account {
          id
          title
          exclude_pii_in_flow_result
          platform_connection {
            platform
          }
        }
      }
    `
  );

  const account = data?.account.length ? data.account[0] : undefined;
  const platform = account?.platform_connection?.platform;

  useEffect(() => {
    if (flowCalled || !platform) {
      return;
    }

    setFlowCalled(true);

    const flowId = queryParams.get("flow_id") || undefined;
    const subscriptionId = queryParams.get("subscription_id") || undefined;
    const internalId = queryParams.get("internal_id") || "";
    const name = queryParams.get("name") || "";
    const email = queryParams.get("email") || "";
    const trial = queryParams.get("trial") === "true";
    const mrr = queryParams.get("mrr") || undefined;

    const properties: Record<string, string> = {};
    for (const key of queryParams.keys()) {
      if (
        ![
          "client_id",
          "flow_id",
          "internal_id",
          "name",
          "email",
          "trial",
          "mrr",
        ].includes(key)
      ) {
        const value = queryParams.get(key);
        if (value) {
          properties[key] = value;
        }
      }
    }
    const payload: FlowPayload =
      subscriptionId && (!platform || platform !== platform_enum.custom)
        ? {
            clientId,
            flowId,
            subscription: {
              paymentProviderId: subscriptionId,
            },
          }
        : {
            clientId,
            flowId,
            subscriber: {
              internalId,
              name,
              email,
              properties,
            },
            subscription: {
              mrr: mrr ? Number(mrr) : undefined,
              trial,
              ...(!!subscriptionId && { platformId: subscriptionId }),
            },
          };
    flow(payload, {
      onCompleted: (result) => {
        setResult(result);
        setCompletedSubscriptionId(subscriptionId);
        setCompletedInteralId(internalId);
      },
      onClosed: () => {
        setClosed(true);
      },
    });
  }, [clientId, flowCalled, queryParams, platform]);

  useEffect(() => {
    if (!result || !closed || !account?.platform_connection?.platform) {
      return;
    }

    const redirectUri = (result as any).redirectUri;

    if (redirectUri) {
      const url = new URL(redirectUri);
      if (
        completedSubscriptionId &&
        account.platform_connection.platform !== platform_enum.custom
      ) {
        url.searchParams.append("subscription_id", completedSubscriptionId);
      } else {
        if (completedInternalId) {
          url.searchParams.append("internal_id", completedInternalId);
        }
        if (completedSubscriptionId) {
          url.searchParams.append("subscription_id", completedSubscriptionId);
        }
        if (!account.exclude_pii_in_flow_result) {
          url.searchParams.append("name", result.flowSession.subscriber.name);
          url.searchParams.append("email", result.flowSession.subscriber.email);
        }
      }
      url.searchParams.append("status", result.status);

      window.location.href = url.toString();
    }
  }, [
    account,
    closed,
    completedInternalId,
    completedSubscriptionId,
    queryParams,
    result,
  ]);

  return (
    <Helmet>
      <title>
        {account?.title && `Cancel subscription - ${account.title}`}
      </title>
    </Helmet>
  );
};

export default NewFlowSession;
